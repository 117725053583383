body {
  font-family: "Roboto", sans-serif;
}

html {
  scroll-behavior: smooth;
}

p {
  font-size: 18px;
}

ol {
  font-size: 25px;
}

ol li {
  font-weight: bold;
}

.inline {
  display: inline-flex;
}

/* CSS Variables */

/* Colors */

.charcoal-black {
  color: #2b3132;
}

.dark-gray {
  color: #535454;
}

.medium-gray {
  color: #5e6367;
}

.navy {
  color: #18396e;
}

.medium-blue {
  color: #2761be;
}

.links-blue {
  color: #1890ff;
}

.light-orange {
  color: #f29147;
}

.dark-pumpkin {
  color: #c05a0a;
}

/* --- Buttons  --- */

.btn-outline-navy {
  width: auto;
  height: 63px;
  font-family: "Roboto-Medium", sans-serif;
  color: #20447f;
  border-color: #20447f;
}

.btn-outline-navy:hover {
  background-color: #20447f;
  color: #f29147;
}

.scroll-link {
  color: #20447f;
}

.scroll-link:hover {
  color: #f29147;
}

.blue-button {
  width: 219px;
  height: 43.76px;
  background: #2761be;
  border-radius: 12px;
  filter: drop-shadow(0px 4px 7px #9bb9ea);
  margin: 0 auto;
  position: relative;
  top: 10px;
  padding-top: 9px;
}

.blue-button:hover {
  background: #3d80eb;
  border: none;
}

.orange-circle {
  width: 57px;
  height: 57px;
  padding: 8px;
  background: #f29147;
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  border-radius: 50px;
  margin: 0 auto;
}

.scrolltop {
  position: relative;
  float: right;
  top: 1400px;
  left: 90px;
}

.btn-ds-submit {
  position: relative;
  margin-bottom: 30px;
}

/* Forms */

.use-case-search {
  height: 52px;
  background: #ffffff;
  border: 2px solid #dad7d7;
  box-sizing: border-box;
  border-radius: 10px;
}

.search {
  height: 52px;
  background: #ffffff;
  border: 2px solid #dad7d7;
  box-sizing: border-box;
  border-radius: 10px;
  padding-left: 45px;
}

#searchIcon {
  position: relative;
  top: -34px;
  left: 20px;
  color: #2761be;
}

/* Nav */

.top-nav {
  height: 70px;
}

.navbar-light .navbar-nav .nav-link {
  color: black;
}

.nav-links a {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 18px;
  color: #373f41;
}

.nav-row {
  position: relative;
  right: 120px;
}

.logo {
  height: 57px;
  position: relative;
  left: 190px;
}

.project-name-text {
  font-family: "Roboto-Medium", sans-serif;
  font-size: 22px;
  font-weight: 500;
  position: relative;
  left: 200px;
  top: 5px;
}

.scroll-top {
  width: 57px !important;
  height: 57px !important;
  padding: 0 !important;
  font-weight: bold !important;
}

.dropdown-menu-right {
  right: -50px;
}

/* Header Backgrounds */

.bg-header {
  width: 100%;
  height: 450px;
  background-size: cover;
}

.home-img {
  background-image: url("../src/images/bg-home.jpg");
  background-repeat: no-repeat;
  position: relative;
  background-position-y: -30px;
}

.blackwhite-img {
  background-image: url("../src/images/bg-secondary.png");
  background-repeat: no-repeat;
  position: relative;
}

.greenwave-img {
  background-image: url("../src/images/bg-greenwave.png");
  background-repeat: no-repeat;
  position: relative;
}

/* Banner Header Text */

.main-header-text {
  font-family: "Roboto-Bold", sans-serif;
  color: #fff;
  font-weight: 700;
  font-size: 35px;
  position: relative;
  top: 120px;
}

.secondary-header-text {
  font-family: "Roboto-Medium", sans-serif;
  font-size: 36px;
  color: #f29147;
  position: relative;
  top: 110px;
}

.summary-header-text {
  font-family: "Roboto-Regular", sans-serif;
  color: #fff;
  font-size: 22px;
  font-weight: 400;
  position: relative;
  top: 110px;
  line-height: 33px;
}

.home-txt {
  top: 120px;
}

.internal-txt {
  top: 100px;
}

/* --- Text --- */

.dark-orange-headline {
  font-family: "Roboto-Medium", sans-serif;
  color: #c05a0a;
  font-weight: 400;
  position: relative;
}

.medium-headline {
  font-family: "Roboto-Medium", sans-serif;
  font-weight: 300;
}

.light-headline {
  font-family: "Roboto-Light", sans-serif;
}

/* Roles Card number */
.orange-number {
  color: #f29147;
  font-weight: 600;
}

.topic-cat-header {
  position: relative;
  top: 32px;
  color: #c05a0a;
  font-family: "Roboto-Medium", sans-serif;
  font-weight: 300;
}

.use-case-txt {
  line-height: 34px;
}

/* Home */

.home-wrapper {
  height: 300px;
}

.home-wrapper {
  height: 500px;
}

/* Use Cases style */

.usecase-card {
  width: 100%;
  height: 265px;
}

/* All */

.card-text {
  font-size: 16.5px;
  line-height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.twentyStar {
  width: 40px;
  height: 40px;
}

/* Data Standard */

.ds-card {
  width: 340px;
  height: 222px;
}

.city-card {
  height: auto;
}

.apr-cardlogo {
  width: 97px;
  border-radius: 15px;
}

.apr-logo {
  width: 220px;
  border-radius: 10px;
}

#topic {
  position: relative;
  height: 900px;
  top: 90px;
}

/* Mobile */

@media all and (min-device-width: 320px) and (max-device-width: 480px) {
  .project-name-text {
    left: 10px;
  }

  .logo {
    left: 0px;
  }

  .bg-header {
    height: 540px;
  }

  .main-header-text {
    position: relative;
    top: 50px;
    text-align: center;
  }

  .secondary-header-text {
    position: relative;
    top: 50px;
    text-align: center;
  }

  .summary-header-text {
    position: relative;
    top: 70px;
    text-align: center;
  }

  .secondary-header-text {
    font-size: 28px;
  }

  .main-header-text {
    font-size: 30px;
  }
}
